/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { parseISO, isAfter, startOfYesterday } from "date-fns";
import groupBy from "lodash/groupBy";
import queryString from "query-string";

import {
  ExhibitionsList,
  ExhibitionsVerticalList,
  Layout,
  Link,
  PageHeading,
} from "components";

function ExhibitionsPage({
  data: {
    allSanityExhibition: { exhibitions },
  },
  location: { search },
}) {
  const displayPastExhibitions = Object.prototype.hasOwnProperty.call(
    queryString.parse(search),
    "past"
  );

  const yesterday = startOfYesterday();

  const { upcomingExhibitions = [], pastExhibitions = [] } = groupBy(
    exhibitions.map(({ node }) => node),
    ({ start_date, end_date }) => {
      const isAfterYesterday =
        (end_date && isAfter(parseISO(end_date), yesterday)) ||
        isAfter(parseISO(start_date), yesterday);
      return isAfterYesterday ? "upcomingExhibitions" : "pastExhibitions";
    }
  );

  const exhibitionsByYear = groupBy(
    displayPastExhibitions ? pastExhibitions : upcomingExhibitions,
    "year"
  );

  const years = Object.keys(exhibitionsByYear).sort((a, b) => b - a);

  return (
    <Layout pageTitle="Exhibitions">
      <PageHeading heading="Exhibitions">
        <Link
          to="/exhibitions"
          variant="links.primary"
          sx={{
            textDecoration: !displayPastExhibitions ? "underline" : "none",
            fontWeight: !displayPastExhibitions ? "700" : "normal",
          }}
        >
          current / upcoming
        </Link>

        <div
          sx={{
            display: "inline-block",
            px: "xs",
          }}
        >
          -
        </div>

        <Link
          to="/exhibitions?past"
          variant="links.primary"
          sx={{
            textDecoration: displayPastExhibitions ? "underline" : "none",
            fontWeight: displayPastExhibitions ? "700" : "normal",
          }}
        >
          past
        </Link>
      </PageHeading>

      {!displayPastExhibitions && upcomingExhibitions.length === 0 && (
        <p>There are currently no current or upcoming exhibitions</p>
      )}

      {!displayPastExhibitions &&
        years.map((year) => (
          <ExhibitionsVerticalList
            key={year}
            id={year}
            heading={displayPastExhibitions ? year : undefined}
            exhibitions={exhibitionsByYear[year].reverse()}
            sx={{ mb: "xl" }}
          />
        ))}

      {displayPastExhibitions &&
        years.map((year) => (
          <ExhibitionsList
            key={year}
            id={year}
            heading={displayPastExhibitions ? year : undefined}
            exhibitions={exhibitionsByYear[year]}
            sx={{ mb: "xl" }}
          />
        ))}
    </Layout>
  );
}

export const query = graphql`
  {
    allSanityExhibition(
      filter: { start_date: { ne: null } }
      sort: { fields: start_date, order: DESC }
    ) {
      exhibitions: edges {
        node {
          name
          year: start_date(formatString: "YYYY")
          start_date
          start_date_string: start_date(formatString: "Do MMMM YYYY")
          description: _rawDescription(resolveReferences: { maxDepth: 10 })
          _id
          end_date
          end_date_string: end_date(formatString: "Do MMMM YYYY")

          artworks {
            artist {
              create_web_page
              first_name
              last_name
              yearOfDeath: date_of_death(formatString: "yyyy")
              yearOfBirth: date_of_birth(formatString: "yyyy")
            }
            is_archived
            name
            work_title
            work_date
            work_image {
              asset {
                gatsbyImageData(height: 220, width: 290)
              }
            }
          }

          image {
            asset {
              gatsbyImageData(height: 220, width: 290)
            }
          }

          work_image__large: image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 600)
            }
          }

          featured_artwork {
            _id
            name
            work_image {
              asset {
                gatsbyImageData(height: 220, width: 290)
              }
            }
            work_image__large: work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 600)
              }
            }
            work_title
            artist {
              _id
              first_name
              last_name
              yearOfDeath: date_of_death(formatString: "yyyy")
              yearOfBirth: date_of_birth(formatString: "yyyy")
            }
            work_date
          }
        }
      }
    }
  }
`;

export default ExhibitionsPage;
